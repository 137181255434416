import { Route } from "next";

import {
  DonationTableParams,
  SingleDonationTableParams,
} from "~/shared/DonationTable/types";

import deepMerge from "./deepMerge";

type SubscriptionStatuses = "active" | "all" | "canceled";

// Acts as a type guard to ensure that the route is valid
function isValidEndpoint<T extends string>(route: any): Route<T> {
  return route;
  // return route;
}

/**
 * These are typed with satisfies for a simpler interface, and will ensure the paths are valid
 * It does not check if the query params are valid
 */
const staticRoutes = {
  // onboarding: '/onboarding',
  // home: '/',
  // supporters: '/supporters',
  // donations: {
  //   payouts: '/donations/payouts',
  // },
  // reports: {
  //   new: '/reports/new',
  // },
  // fundraising: {
  //   campaigns: '/fundraising/campaigns',
  //   newCampaign: '/fundraising/campaigns/new',
  //   channels: '/fundraising/channels',
  //   goals: '/fundraising/goals',
  //   newGoal: '/fundraising/goals/new',
  //   templates: '/fundraising/templates',
  //   newTemplate: '/fundraising/templates/new',
  // },
  // integrations: {
  //   webhooks: '/integrations/webhook',
  //   newWebhook: '/integrations/webhook/new',
  // },
  // settings: {
  //   organizations: '/settings/organization',
  //   newOrganization: '/settings/organization/new',
  //   teamMembers: '/settings/team-members',
  //   inviteTeamMember: '/settings/team-members/invite',
  //   auditLogs: '/settings/logs',
  // },
} satisfies Record<string, Record<string, Route> | Route>;

/**
 * These are enforced with isValidEndpoint as typescript didn't get along with satisfies when using a function
 * Again, it does not check if the query params are valid
 */
const dynamicRoutes = {
  donations: {
    donation: (id: string) => isValidEndpoint(`/donations/${id}`),
    list: (params: DonationTableParams) =>
      isValidEndpoint(`/donations?view=${params.view}&status=${params.status}`),
    payouts: "/donations/payouts" as Route,
    subscription: (id: string) => isValidEndpoint(`/subscriptions/${id}`),
    subscriptions: (status: SubscriptionStatuses) =>
      isValidEndpoint(`/subscriptions?status=${status}`),
  },
  fundraising: {
    campaign: (id: string, status?: SingleDonationTableParams["status"]) =>
      isValidEndpoint(
        `/fundraising/campaigns/${id}?status=${status || "succeeded"}`,
      ),
    campaignInsights: (id: string) =>
      isValidEndpoint(`/fundraising/campaigns/${id}/insights`),
    campaigns: "/fundraising/campaigns" as Route,
    campaignSettings: (id: string) =>
      isValidEndpoint(`/fundraising/campaigns/${id}/settings`),
    channel: (
      campaignId: string,
      channelId: string,
      status?: SingleDonationTableParams["status"],
    ) =>
      isValidEndpoint(
        `/fundraising/campaigns/${campaignId}/channels/${channelId}?status=${status || "succeeded"}`,
      ),
    channelInsights: (campaignId: string, channelId: string) =>
      isValidEndpoint(
        `/fundraising/campaigns/${campaignId}/channels/${channelId}/insights`,
      ),
    channelQr: (campaignId: string, channelId: string) =>
      isValidEndpoint(
        `/fundraising/campaigns/${campaignId}/channels/${channelId}/qrcode`,
      ),
    channels: "/fundraising/channels" as Route,
    channelSettings: (campaignId: string, channelId: string) =>
      isValidEndpoint(
        `/fundraising/campaigns/${campaignId}/channels/${channelId}/settings`,
      ),
    channelShare: (campaignId: string, channelId: string) =>
      isValidEndpoint(
        `/fundraising/campaigns/${campaignId}/channels/${channelId}/share`,
      ),
    customField: (id: string) =>
      isValidEndpoint(`/fundraising/custom-fields/${id}`),
    customFields: "/fundraising/custom-fields" as Route,
    goal: (id: string) => isValidEndpoint(`/fundraising/goals/${id}`),
    goals: "/fundraising/goals" as Route,
    newCampaign: "/fundraising/campaigns/new" as Route,
    newChannel: (campaignId: string) =>
      isValidEndpoint(`/fundraising/campaigns/${campaignId}/new`),
    newCustomField: "/fundraising/custom-fields/new" as Route,
    newGoal: "/fundraising/goals/new" as Route,
    newTemplate: "/fundraising/templates/new" as Route,
    template: (id: string) => isValidEndpoint(`/fundraising/templates/${id}`),
    templates: "/fundraising/templates" as Route,
  },
  home: "/" as Route,
  integrations: {
    newWebhook: "/integrations/webhook/new" as Route,
    webhook: (id: string) => isValidEndpoint(`/integrations/webhook/${id}`),
    webhooks: "/integrations/webhook" as Route,
  },
  onboarding: "/onboarding" as Route,
  reports: {
    new: "/reports/new" as Route,
  },
  settings: {
    access: (id: string) =>
      isValidEndpoint(`/settings/organization/${id}/access`),
    auditLog: (id: string) => isValidEndpoint(`/settings/logs/${id}`),
    auditLogs: "/settings/logs" as Route,
    bankAccount: (orgId: string, id: string) =>
      isValidEndpoint(`/settings/organization/${orgId}/bank-accounts/${id}`),
    bankAccounts: (orgId: string) =>
      isValidEndpoint(`/settings/organization/${orgId}/bank-accounts`),
    governance: (id: string) =>
      isValidEndpoint(`/settings/organization/${id}/governance`),
    group: (orgId: string, groupId: string) =>
      isValidEndpoint(`/settings/organization/${orgId}/group/${groupId}`),
    groupAccess: (orgId: string, groupId: string) =>
      isValidEndpoint(
        `/settings/organization/${orgId}/group/${groupId}/access`,
      ),
    groups: (id: string) =>
      isValidEndpoint(`/settings/organization/${id}/groups`),
    inviteTeamMember: "/settings/team-members/invite" as Route,
    newBankAccount: (orgId: string) =>
      isValidEndpoint(`/settings/organization/${orgId}/bank-accounts/new`),
    newGroup: (id: string) =>
      isValidEndpoint(`/settings/organization/${id}/groups/new`),
    newOrganization: "/settings/organization/new" as Route,
    newPaymentMethodDomain: (orgId: string) =>
      isValidEndpoint(
        `/settings/organization/${orgId}/payment-method-domains/new`,
      ),
    newPerson: (id: string) =>
      isValidEndpoint(`/settings/organization/${id}/people/new`),
    organization: (id: string) =>
      isValidEndpoint(`/settings/organization/${id}`),
    organizations: "/settings/organization" as Route,
    paymentMethodDomains: (orgId: string) =>
      isValidEndpoint(`/settings/organization/${orgId}/payment-method-domains`),
    person: (orgId: string, id: string) =>
      isValidEndpoint(`/settings/organization/${orgId}/people/${id}`),
    teamMember: (id: string) => isValidEndpoint(`/settings/team-members/${id}`),
    teamMembers: "/settings/team-members" as Route,
  },
  supporter: (id: string) => isValidEndpoint(`/supporters/${id}`),
  supporters: "/supporters" as Route,
};

// COMBINED ROUTES
const routes = deepMerge(staticRoutes, dynamicRoutes);

export default routes;
